

export default function app () {
  document.addEventListener( 'DOMContentLoaded', ()=>{

    const createItem = ( parent:HTMLDivElement, i:number ):void => {

      const ElPar = document.createElement( 'div' );
      const ElTop = document.createElement( 'div' );
      const ElBot = document.createElement( 'div' );

      ElPar.classList.add( 'm-mand-item' );
      ElTop.classList.add( 'm-mand-item-top', 'm-mand-item-circle' );
      ElBot.classList.add( 'm-mand-item-bot', 'm-mand-item-circle' );

      ElPar.style.transform = 'rotate('+i*9+'deg)';
      
      ElPar.appendChild( ElTop );
      ElPar.appendChild( ElBot );
      parent.appendChild( ElPar );

    };

    
    for( let n=0; n<2; n++ ) {

      const El = document.createElement( 'div' );
      El.setAttribute( 'id', 'm-mand' );
      El.style.transform = 'rotate('+n*90+'deg)';

      for( let i=0; i<20; i++ ) {
        setTimeout( createItem, i*150, El, i );
      }

      document.body.appendChild( El );
    }


  } );
}